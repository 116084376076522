// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

// Vendor
import $ from 'jquery';
import Tether from 'tether';
window.$ = window.jQuery = $;
window.Tether = Tether;
require('bootstrap');

// Modules
import Link from '../_modules/link/link';
import ContactModal from '../_modules/contact-modal/contact-modal';

$(() => {
  new ContactModal();

  if ($('.container-contact').length > 0) {
    var map = $('.container-contact .contact-map-embed');

    map.click(function(e) {
      map.removeClass('scroll-off'); // set the pointer events true on click
    });

    map.mouseleave(function(e) {
      map.addClass('scroll-off');
    });
  }
});
