'use strict';

export default class ContactModal {
  constructor() {
    this.name = 'contact-modal';

    if ($('#contact-quote-modal').length > 0) {
      // Update form
      $('#contact-quote-modal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var screen = button.data('screen');
        var products = button.data('products');
        var modal = $(this);

        // Send form message
        modal.find('#contact-submit').click(function(e) {
        });

        if (screen === 'quote') {
          if (products && products.length > 0) {
            for (var product of products) {
              modal.find('.modal-body #' + product).prop('checked', true);
            }
          }
        } else if (screen === 'developer') {
          modal.find('.contact-product-select').attr({ hidden: true });
          modal.find('#developer-contact').prop('checked', true);
          modal.find('.modal-title').html('Join Developer Community');
          modal.find('.modal-sub-heading').html('Thanks for your interest in joining our developer commmunity. Please fill out your details below and we\'ll get back to you shortly.');
        }
      })
    }
  }
}
